import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IHealthCheckEntity } from '../../interfaces/api/health-check-entity';

export const adapter: EntityAdapter<IHealthCheckEntity> = createEntityAdapter<IHealthCheckEntity>(
  {}
);
export const messageableFactory = MessageableFactory.forFeature<'HealthCheck'>('HealthCheck');
export const signalableFactory = SignalableFactory.forFeature<'HealthCheck'>('HealthCheck');

export const initialState = adapter.getInitialState({});
